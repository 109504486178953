import React from "react"
import styles from "../styles/components/faq.module.scss"
import Reveal from "react-reveal/Reveal"
import TextBlock from "./textBlock"

import buttonSlime from "../images/button-slime.png"
//import backgroundImage from "../images/squiggle-pattern-background.png"
import backgroundImage from "../images/press-background.jpg"

const Press = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = { showAll: false, defaultListLength: 4 }
    this.toggleShowAll = this.toggleShowAll.bind(this)
  }

  toggleShowAll() {
    this.setState({ showAll: !this.state.showAll })
  }

  render() {
    const textBlocks = [
      {
        header:
          "Lorem ipsum dolor sit amet, consectetuer adipiscing  elit, sed diam",
        bodyText:
          "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in",
        link: "https://www.nick.com/",
      },
      {
        header: "Lorem ipsum dolor, consectetuer adipiscing  elit, sed diam",
        bodyText:
          "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in",
        link: "https://www.nick.com/",
      },
      {
        header: "Lorem ipsum dolor sit amet, consectetuer adipiscing  elit",
        bodyText:
          "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in",
        link: "https://www.nick.com/",
      },
      {
        header: "Lorem ipsum dolor sit amet, consectetuer",
        bodyText:
          "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in",
        link: "https://www.nick.com/",
      },
      {
        header: "Lorem ipsum dolor sit elit, sed diam",
        bodyText:
          "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in",
        link: "https://www.nick.com/",
      },
      {
        header: "Lorem ipsum dolor  adipiscing  elit, sed diam",
        bodyText:
          "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in",
        link: "https://www.nick.com/",
      },
      {
        header: "Lorem ipsum dolor sit amet, consectetuer sed diam",
        bodyText:
          "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in",
        link: "https://www.nick.com/",
      },
      {
        header:
          "Lorem ipsum dolor sit amet, consectetuer adipiscing  elit, sed diam",
        bodyText:
          "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in",
        link: "https://www.nick.com/",
      },
    ]

    return (
      <div
        style={{
          backgroundImage: "url(" + backgroundImage + ")",
          backgroundSize: "cover",
          paddingTop: "150px",
        }}
      >
        <div className={styles.container}>
          <Reveal effect="fadeIn" fraction={0.5}>
            <div className={styles.header}>Press</div>
          </Reveal>
          <div className={styles.flexContainer}>
            {textBlocks.map((item, i) => {
              if (!this.state.showAll && i >= this.state.defaultListLength) {
                return
              } else {
                return (
                  <Reveal effect="fadeInUpShorter" fraction={0.5}>
                    <TextBlock
                      key={i}
                      header={item.header}
                      bodyText={item.bodyText}
                      link={item.link}
                      isExpandable={false}
                      whiteText={true}
                    />
                  </Reveal>
                )
              }
            })}
          </div>
          <div className={styles.showMoreButton} onClick={this.toggleShowAll}>
            {/*<div className={styles.buttonSlime}>
              <img src={buttonSlime} alt="slime" />
            </div>*/}
            <span>{this.state.showAll ? "Show Less" : "Show More"}</span>
          </div>
        </div>
      </div>
    )
  }
}

export default Press

import React from "react"
import styles from "../styles/components/about.module.scss"

import Reveal from "react-reveal/Reveal"

import slimeBoy from "../images/about-slime-boy.png"

const About = ({ props }) => {
  return (
    <div className={styles.container}>
        <div className={styles.slimeBoy}>
          <img src={slimeBoy} />
        </div>
      <div className={styles.flexContainer}>
          <div className={styles.title}>About Slime City</div>

          <div className={styles.text}>
            Welcome to SLIME CITY – the greenest, most awesomest place on plant
            Earth. Here you will SEE SLIME, FEEL SLIME and even get the chance
            to receive Nickelodeon’s ultimate honor of… BEING SLIMED! <br />
            <br />
            From hanging at Slime HQ to dancing at the Slime Light Club, to
            making your mark at the Great Wall AND the Great Hall (of Slime!),
            Slime flies when you’re having fun! It’s the ultimate Slime
            experience as you soak in all the ooey gooey green goodness at the
            Slime Bubble-torium, and the Slime-tennial Park.
            <br />
            <br /> So, get ready…we’re bringing the SLIME Big Time!
          </div>
      </div>
    </div>
  )
}

export default About

import React from "react"
import { Link } from "gatsby"
import Helmet from "react-helmet"
import Scroll from "react-scroll"
import "../styles/animate.css"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

import AgeGate from "../components/ageGate"
import Header from "../components/header"
import Hero from "../components/hero"
import About from "../components/about"
import ImageFeed from "../components/imageFeed"
import PlanVisit from "../components/planVisit"
import GroupInfo from "../components/groupInfo"
import EmailCollect from "../components/emailCollect"
import Faq from "../components/faq"
import ContactForm from "../components/contactForm"
import VisitAtlanta from "../components/visitAtlanta"
import Press from "../components/press"
import ImageSection from "../components/imageSection"
import Footer from "../components/footer"

import placeholderImage from "../images/image-placeholder-2.png"
import placeholderImagePeace from "../images/image-placeholder-3.png"
import slimeBasket from "../images/image-placeholder-4.png"
import prefooterImage from "../images/image-placeholder-5.png"
import skateboardImage from "../images/skateboard-background.jpg"
import slimeShoes from "../images/slime-shoes.jpg"

let Element = Scroll.Element
let ScrollLink = Scroll.Link

var digitalData = {
  page: {
    pageInfo: {
      pageName: "slimecity|home",
    },
    v: {
      pv: "true",
      channel: "home",
      pageType: "home",
      pageFranchise: "slimecity 2019",
      pageURL: "http://nickslimecity.com",
      brandID: "slimecity-us",
      language: "en-us",
      countryName: "us",
      vendor: "Weston Baker Creative Group",
      activity: "pageView",
    },
  },
}

var initialDataLayer = `var digitalData = {
  page: {
    pageInfo: {
      pageName: "slimecity|home",
    },
    v: {
      pv: "true",
      channel: "home",
      pageType: "home",
      pageFranchise: "slimecity 2019",
      pageURL: "http://nickslimecity.com",
      brandID: "slimecity-us",
      language: "en-us",
      countryName: "us",
      vendor: "Weston Baker Creative Group",
      activity: "pageView",
    },
  },
}`

var virtualPageLoad = `_satellite.track("virtualPageLoad")`

const snapPixel = `(function(e,t,n){if(e.snaptr)return;var a=e.snaptr=function()
{a.handleRequest?a.handleRequest.apply(a,arguments):a.queue.push(arguments)};
a.queue=[];var s='script';r=t.createElement(s);r.async=!0;
r.src=n;var u=t.getElementsByTagName(s)[0];
u.parentNode.insertBefore(r,u);})(window,document,
'https://sc-static.net/scevent.min.js');

snaptr('init', '87fe43d8-588f-455d-9a43-abcd06654a89', {
'user_email': '__INSERT_USER_EMAIL__'
});

snaptr('track', 'PAGE_VIEW');`

const googleAdsScript = `window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'AW-743682284');`

const googleAdsConversionPage = `gtag('event', 'conversion', {'send_to': 'AW-743682284/XfsHCLDM2p0BEOzhzuIC'});`

const facebookPixel = `
!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;
n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,
document,'script','//connect.facebook.net/en_US/fbevents.js');

fbq('init', '331289063738687');
fbq('track', "PageView");`

const facebookNoScript = `<img
  height="1"
  width="1"
  style="display:none"
  src="https://www.facebook.com/tr?id=331289063738687&ev=PageView&noscript=1"
/>`

const twitterPixel = `
!function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
},s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='//static.ads-twitter.com/uwt.js',
a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');
// Insert Twitter Pixel ID and Standard Event data below
twq('init','o1uge');
twq('track','PageView');
`

var axel = Math.random() + ""
    var a = axel * 10000000000000

const floodlightScript = `<img src="https://ad.doubleclick.net/ddm/activity/src=6220336;type=sitevisi;cat=nle_s0;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;ord=' + a + '?" width="1" height="1" alt=""/>`

const floodlightTag = `var axel = Math.random() + "";
var a = axel * 10000000000000;
document.write('<iframe src="https://6220336.fls.doubleclick.net/activityi;src=6220336;type=sitevisi;cat=nle_s0;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;ord=' + a + '?" width="1" height="1" frameborder="0" style="display:none"></iframe>');`

const floodlightNoScript = `<iframe src="https://6220336.fls.doubleclick.net/activityi;src=6220336;type=sitevisi;cat=nle_s0;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;ord=1?" width="1" height="1" frameborder="0" style="display:none"></iframe>`



function new_script(src) {
  return new Promise(function(resolve, reject) {
    var script = document.createElement("script")
    script.src = src
    script.addEventListener("load", function() {
      resolve()
    })
    script.addEventListener("error", function(e) {
      reject(e)
    })
    document.body.appendChild(script)
  })
}
// Promise Interface can ensure load the script only once.
var my_script = new_script("//assets.adobedtm.com/launch-ENd7fdb855006741daa68500a01fb05703.min.js")
/* DEV SCRIPT */
/*
var my_script = new_script(
  "//assets.adobedtm.com/launch-EN0d2733b1849e4a5cbe198827adeec32a-development.min.js")
*/

const IndexPage = class extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      under13: null,
      ageGateOpen: true,
      dataLayerString: initialDataLayer,
      status: "start",
      dataLayerDidUpdate: false,
      virtualPageLoad: virtualPageLoad,
    }
    this.loadFloodlightTag = this.loadFloodlightTag.bind(this)
    this.updateDataLayer = this.updateDataLayer.bind(this)
    this.loadEmailScript = this.loadEmailScript.bind(this)
    this.handleAgeGate = this.handleAgeGate.bind(this)
    this.checkCookie = this.checkCookie.bind(this)
    this.setCookie = this.setCookie.bind(this)
    this.getCookie = this.getCookie.bind(this)
  }

  do_load = () => {
    var self = this
    my_script
      .then(function() {
        self.setState({ status: "done" })
      })
      .catch(function() {
        self.setState({ status: "error" })
      })
  }

  componentDidMount() {
    /*
    if (this.state.ageGateOpen) {
      if (typeof document !== "undefined") {
        document.body.style.overflow = "hidden"
      }
    } else {
      if (typeof document !== "undefined") {
        document.body.style.overflow = ""
      }
    }
    */
    //this.loadScript()

    /*const script = document.createElement("script")
    script.src =
      "//assets.adobedtm.com/launch-EN0d2733b1849e4a5cbe198827adeec32a-development.min.js"
    script.type = "text/javascript"
    script.async = true

    document.body.appendChild(script)*/

    this.checkCookie()

    // trigger initial Adobe Analytics Data Layer
    //this.loadAnalyticsData()
    //this.setDataLayer()
  }
  /*------ Adobe Analytics ------*/
  setDataLayer() {
    //console.log(digitalData.page.pageInfo.pageName)
    //digitalData.page.pageInfo.pageName = "slimecity|about"
    //console.log(digitalData.page.pageInfo.pageName)
  }

  loadAnalyticsData() {
    /*const script = document.createElement("script")
    script.src = this.setDataLayer()
    script.type = "text/javascript"

    document.body.appendChild(script)*/
  }

  updateDataLayer(section) {
    this.setState({
      dataLayerString: initialDataLayer.replace(/home/g, section),
      dataLayerDidUpdate: true,
      virtualPageLoad: this.state.virtualPageLoad,
    })
    /*
    const script = document.createElement("script")
    script.innerHTML = '_satellite.track("virtualPageLoad")'
    script.type = "text/javascript"

    document.body.appendChild(script)
    */
  }
  /* ---------------------------*/
  loadFloodlightTag() {
    /*--
Start of Floodlight Tag: Please do not remove
Activity name of this tag: NLE_Slime City_2019_Site Visit
URL of the webpage where the tag is expected to be placed: http://slimecity.com/
This tag must be placed between the <body> and </body> tags, as close as possible to the opening tag.
Creation Date: 05/06/2019
--*/
    const fragment = document.createDocumentFragment()


    var axel = Math.random() + ""
    var a = axel * 10000000000000

    const iframe = document.createElement("iframe")
    iframe.src =
      "https://6220336.fls.doubleclick.net/activityi;src=6220336;type=sitevisi;cat=nle_s0;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;ord=" +
      a +
      "?"
    iframe.width = "1"
    iframe.height = "1"
    iframe.frameborder = "0"
    iframe.style.display = "none"

    fragment.appendChild(iframe)


    /*const script = document.createElement("script")
    script.src = withPrefix("/js/pixels/floodlightTag.js")
    script.type = "text/javascript"

    fragment.appendChild(script)*/

    const noscript = document.createElement("noscript")
    noscript.innerHTML = `<img src="https://ad.doubleclick.net/ddm/activity/src=6220336;type=sitevisi;cat=nle_s0;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;ord=1?" width="1" height="1" alt=""/>`

    fragment.appendChild(noscript)

    document.body.appendChild(fragment)
    /*-- End of Floodlight Tag: Please do not remove --*/
  }

  loadEmailScript() {
    const script = document.createElement("script")
    script.src = "https://funnel.mtvnservices.com/widget/js/funnel.js"
    script.type = "text/javascript"

    document.body.appendChild(script)
  }

  handleAgeGate(under13) {
    console.log("under13: " + under13)
    if (under13 === false) {
      this.loadEmailScript()
      this.loadFloodlightTag()
    }
    this.setState({ under13: under13, ageGateOpen: false })
    if (typeof document !== "undefined") {
      document.body.style.overflow = ""
    }
    this.setCookie("under13", under13, 1)
  }

  getCookie(cname) {
    let name = cname + "="
    let decodedCookie = decodeURIComponent(document.cookie)
    let ca = decodedCookie.split(";")
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) === " ") {
        c = c.substring(1)
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length)
      }
    }
    return ""
  }

  checkCookie() {
    //document.cookie = "under13=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"
    let under13 = this.getCookie("under13")
    if (under13 !== "") {
      if (under13 === "true") {
        this.setState({ ageGateOpen: false, under13: true })
      } else if (under13 === "false") {
        this.setState({ ageGateOpen: false, under13: false })
        this.loadEmailScript()
        this.loadFloodlightTag()
      }
    }
  }

  setCookie(cname, cvalue, exdays) {
    let d = new Date()
    //d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
    // cookie set to last one hour
    d.setTime(d.getTime() + 3600000)
    let expires = "expires=" + d.toGMTString()
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/"
  }

  render() {
    var self = this
    if (self.state.status === "start") {
      self.state.status = "loading"
      setTimeout(function() {
        self.do_load()
      }, 0)
    }
    return (
      <>
        <Helmet>
          {/*------ Adobe Analytics ------*/}
          <script>{this.state.dataLayerString}</script>

          {self.state.status === "done" && (
            <script
              innerHTML={this.state.virtualPageLoad}
              onload={console.log("virtualPageLoad")}
            />
          )}
        </Helmet>

        {this.state.under13 === false && (
          <Helmet>
            {/*-- Snap Pixel Code --*/}
            <script type="text/javascript">{snapPixel}</script>

            {/*-- Global site tag (gtag.js) - Google Ads: 743682284 --*/}
            <script
              async
              src="https://www.googletagmanager.com/gtag/js?id=AW-743682284"
            />
            <script>{googleAdsScript}</script>
            {/*-- Event snippet for NLE_Slime City_2019_Site Visit_Conversion conversion page --*/}
            <script>{googleAdsConversionPage}</script>

            {/*-- Facebook Pixel Code --*/}
            <script>{facebookPixel}</script>
            <noscript innerHTML={facebookNoScript} />
            {/*-- End Facebook Pixel Code --*/}

            {/*-- Twitter universal website tag code --*/}
            <script>{twitterPixel}</script>
            {/*-- End Twitter universal website tag code --*/}

           {/* <!--
Start of Floodlight Tag: Please do not remove
Activity name of this tag: NLE_Slime City_2019_Site Visit
URL of the webpage where the tag is expected to be placed: http://slimecity.com/
This tag must be placed between the <body> and </body> tags, as close as possible to the opening tag.
Creation Date: 05/17/2019
--> 
<script type="text/javascript">{floodlightScript}</script>
<noscript>{floodlightNoScript}</noscript>
{/*<!-- End of Floodlight Tag: Please do not remove -->*/}
          </Helmet>
        )}
        <Layout>
          <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
          {this.state.ageGateOpen && (
            <AgeGate handleAgeGate={this.handleAgeGate} />
          )}
          {!this.state.ageGateOpen && (
            <>
              <Header
                updateDataLayer={this.updateDataLayer}
                under13={this.state.under13}
              />
              <div id="tickets">
                <Hero under13={this.state.under13} />
              </div>
              <div id="about">
                <About />
              </div>
              {/*<div id="gallery">
            <ImageFeed />
          </div>*/}
              <div id="plan-visit">
                <PlanVisit under13={this.state.under13}/>
              </div>
              <div id="group-info">
                <GroupInfo under13={this.state.under13} />
              </div>

              {this.state.under13 === false && (
                <div id="email">
                  <EmailCollect under13={false} />
                </div>
              )}
              <ImageSection imageURL={skateboardImage} />

              <div id="faq">
                <Faq under13={this.state.under13} />
              </div>
              {/*<div
            style={{
              width: "100%",
              height: "auto",
              marginBottom: "-190px",
            }}
          >
            <img src={placeholderImagePeace} alt="" />
          </div>*/}
              {!this.state.under13 && (
                <div id="contact">
                  <ContactForm />
                </div>
              )}
              {/*
          <div id="visit-atlanta">
            <VisitAtlanta under13={this.state.under13} />
          </div>
          <div
            style={{ width: "100%", height: "auto", marginBottom: "-175px" }}
          >
            <img src={slimeBasket} alt="" />
          </div>
          */}
              {/*{!this.state.under13 && (
            <div id="press">
              <Press />
            </div>
          )}*/}
              <ImageSection imageURL={slimeShoes} />
              <Footer under13={this.state.under13}/>
            </>
          )}
        </Layout>
      </>
    )
  }
}

export default IndexPage

import React from "react"
import styles from "../styles/components/group-info.module.scss"
import Reveal from "react-reveal/Reveal"

const GroupInfo = (props) => {
  return (
    <div className={styles.container}>
        <div className={styles.header}>Group and Private Event Information</div>
        <div className={styles.body}>
          Spend some quality Slime with your group at SLIME CITY!
          <br />
          <br />
          For pricing & availability for large groups and private events, please
          contact our Slime City Event Coordinator by emailing{" "}
          {!props.under13 && (
            <a
              style={{ textDecoration: "none", color: "#4f0385" }}
              href="mailto:slimecity@nick.com"
            >
              slimecity@nick.com.
            </a>
          )}
          {props.under13 && ('slimecity@nick.com')}
        </div>
      {/*
      <div className={styles.flexContainer}>
        <div className={styles.column}>
          <div className={styles.subheader}>Groups</div>
          <div className={styles.text}>
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
            nonummy nibh euismod{" "}
          </div>
          <div className={styles.email}>email@slimecity.com</div>
        </div>
        <div className={styles.column}>
          <div className={styles.subheader}>Parties</div>
          <div className={styles.text}>
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
            nonummy nibh euismod{" "}
          </div>
          <div className={styles.email}>email@slimecity.com</div>
        </div>
        <div className={styles.column}>
          <div className={styles.subheader}>Events</div>
          <div className={styles.text}>
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
            nonummy nibh euismod{" "}
          </div>
          <div className={styles.email}>email@slimecity.com</div>
        </div>
      </div>
      */}
    </div>
  )
}

export default GroupInfo

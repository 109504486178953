import React from "react"
import styles from "../styles/components/plan-visit.module.scss"
import Reveal from "react-reveal/Reveal"
//import backgroundImage from "../images/image-placeholder-1.png"
import backgroundImage from "../images/plan-visit-background-tinted.jpg"
import cornerSlime from "../images/slime-bottom-left-corner.png"

const PlanVisit = props => {
  return (
    <div
      className={styles.container}
      style={{
        backgroundImage: "url(" + backgroundImage + ")",
      }}
    >
        <div className={styles.modalContainer}>
          {/*<div className={styles.slimeBorder}>
          <img src={cornerSlime} alt="" />
        </div>*/}
            <div className={styles.header}>Plan Your Visit</div>
          <div className={styles.flexContainer}>
            <div className={styles.leftColumn}>
              <div className={styles.textBlock}>
                  <div className={styles.subheader}>Admission</div>
                  <p>
                    General Admission: $25 FOR ALL JUNE DATES ONLY. HURRY!
                    LIMITED TIME OFFER EARLY-BIRD PRESALE ENDS JUNE 14TH! 
                  </p>
                  <p>
                    (JUNE
                    14th – SOFT OPENING – ONE DAY ONLY! SPECIAL PRICE - $20)
                  </p>
                  <p>
                    <i>
                      Children under 2 are free. Children of all ages must be
                      accompanied by a ticketed adult.
                    </i>
                  </p>
                  <p style={{ paddingBottom: 0 }}>
                    <i>
                      Tickets must be purchased in advance. Limit 8 tickets per
                      transaction. Tickets are valid ONLY at the assigned date
                      and time slot.
                    </i>
                  </p>
              </div>
              <div className={styles.textBlock}>
                  <div className={styles.subheader}>Location</div>
                  <p style={{ paddingBottom: 0 }}>
                    Piedmont PeachTree Crossing
                  </p>
                  <p style={{ paddingBottom: 0 }}>3330 Piedmont Road NE</p>
                  <p style={{ paddingBottom: 0 }}>Atlanta, GA</p>
              </div>
              <div className={styles.textBlock}>
                  <div className={styles.subheader}>Hours</div>
                  <p style={{ paddingBottom: 0 }}>Saturday - 9:00am-8pm</p>
                  <p style={{ paddingBottom: 0 }}>Sunday - 10am-7pm</p>
                  <p style={{ paddingBottom: 0 }}>
                    Wednesday, Thursday, Friday – 10:30am-6:30pm
                  </p>
                  <p style={{ paddingBottom: 0 }}>
                    Monday and Tuesday – Closed
                  </p>
              </div>
            </div>
            <div className={styles.rightColumn}>
              <div className={styles.textBlock}>
                  <div className={styles.subheader}>What Should I Wear?</div>
                  <p style={{ paddingBottom: 0 }}>
                    Come dressed to play! And don’t worry, we’ve thought of
                    everything. Everyone will receive a poncho to wear during
                    their sliming so you can get right back into your car
                    afterward!
                  </p>
              </div>
              <div className={styles.textBlock}>
                  <div className={styles.subheader}>Directions</div>
                  <p>
                    From I-85 South: exit #88 (Lenox Rd), turn right, go about 3
                    miles and turn left on Peachtree Road. and go about 2 miles
                    and turn right on Piedmont Road NE. Nickelodeon Slime City
                    is located in the Piedmont Peachtree Crossing Shopping
                    Center on the left.
                  </p>
                  <p>
                    From I-85 North to GA: 400 North (toll road) to exit 2, turn
                    left, go to 2nd red light, turn left onto Piedmont Road NE,
                    Nickelodeon Slime City is located in the Piedmont Peachtree
                    Crossing Shopping Center is on the right.
                  </p>
                  {!props.under13 && (
                    <p style={{ paddingBottom: 0 }}>
                    <a
                      style={{
                        textDecoration: "none",
                        color: "inherit",
                        fontWeight: "bold",
                        letterSpacing: "1px",
                      }}
                      target="__blank"
                      href="https://www.google.com/maps/place/3330+Piedmont+Rd+NE,+Atlanta,+GA+30305/@33.8458219,-84.3752399,18.25z/data=!4m5!3m4!1s0x88f505f561b75747:0x192a26fc6ce9461a!8m2!3d33.8457987!4d-84.3744938"
                    >
                      GET DIRECTIONS
                    </a>
                  </p>
                  )}
              </div>
            </div>
          </div>
        </div>
    </div>
  )
}

export default PlanVisit

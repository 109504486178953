import React from "react"
import styles from "../styles/components/age-gate.module.scss"

import Dropdown from "../components/dropdown"
//import Logo from "../images/slimecity-logo.png"
import Logo from "../images/logo-with-atlanta-green.png"
//import background from "../images/pattern-background.png"
import background from "../images/age-gate-background.jpg"
import slimeUpperLeft from "../images/slime-top-left-corner.png"
import slimeBottomRight from "../images/slime-bottom-right-corner.png"

const AgeGate = class extends React.Component {
  constructor(props) {
    super(props)

    this.state = { month: null, day: null, year: null }

    this.handleSubmit = this.handleSubmit.bind(this)
    this.onChange = this.onChange.bind(this)
  }
  // function called in Dropdown.js to update active date selections
  onChange(label, selection) {
    switch (label) {
      case "Month":
        this.setState({ month: selection })
        break
      case "Day":
        this.setState({ day: selection })
        break
      case "Year":
        this.setState({ year: selection })
        break
      default:
        break
    }
  }

  validateAge() {
    // check if year is less than 13 years since current
    let currYear = new Date().getFullYear()
    let currMonth = new Date().getMonth() + 1
    let currDay = new Date().getDate()
    const month = parseInt(this.state.month)
    const day = parseInt(this.state.day)
    const year = parseInt(this.state.year)
    if (year > currYear - 13) {
      //this.setState({ under13: true, ageGateOpen: false })
      this.props.handleAgeGate(true)
      return true
    }
    // else need to check by month and day
    else if (year === currYear - 13 && month > currMonth) {
      //this.setState({ under13: true, ageGateOpen: false })
      this.props.handleAgeGate(true)
      return true
    } else if (year === currYear - 13 && month === currMonth && day > currDay) {
      //this.setState({ under13: true, ageGateOpen: false })
      this.props.handleAgeGate(true)
      return true
    } else {
      //this.setState({ under13: false, ageGateOpen: false })
      this.props.handleAgeGate(false)
      return false
    }
  }

  handleSubmit() {
    let under13
    if (
      this.state.month === null ||
      this.state.day === null ||
      this.state.year === null
    ) {
      this.setState({ showErrorMessage: true })
      console.log("Please enter all fields")
    } else {
      under13 = this.validateAge()
    }
  }

  render() {
    const months = [
      { label: "January", value: "1" },
      { label: "February", value: "2" },
      { label: "March", value: "3" },
      { label: "April", value: "4" },
      { label: "May", value: "5" },
      { label: "June", value: "6" },
      { label: "July", value: "7" },
      { label: "August", value: "8" },
      { label: "September", value: "9" },
      { label: "October", value: "10" },
      { label: "November", value: "11" },
      { label: "December", value: "12" },
    ]
    let days = []
    for (let i = 1; i < 32; i++) {
      days.push({ label: i, value: i })
    }
    let maxOffset = 100
    let currYear = new Date().getFullYear()
    let years = []
    for (let i = 0; i <= maxOffset; i++) {
      years.push({ label: currYear - i, value: currYear - i })
    }
    return (
      /* changed from contain to cover */
      <div
        className={styles.container}
        style={{
          backgroundImage: "url(" + background + ")",
          backgroundSize: "cover",
        }}
      >
        <div className={styles.ageGate}>
          <div className={styles.ageGateContainer}>
            {/*<div className={styles.slime}>
              <img src={slimeUpperLeft} alt="" />
            </div>
            <div className={styles.slimeBottomRight}>
              <img src={slimeBottomRight} alt="" />
            </div>*/}
            <div className={styles.dropShadow} />
            <div className={styles.logo}>
              <img src={Logo} alt="" />
            </div>
            <div className={styles.flexContainer}>
              <div className={styles.header}>
                Hey Atlanta! Please enter your birthday so we can serve up the
                Nickelodeon Slime City experience that is right for you.
              </div>
              <div className={styles.instruction}>
                Please enter your date of birth
              </div>
              <div className={styles.dateDropdowns}>
                <Dropdown
                  label="Month"
                  options={months}
                  onChange={this.onChange}
                />
                <Dropdown label="Day" options={days} onChange={this.onChange} />
                <Dropdown
                  label="Year"
                  options={years}
                  onChange={this.onChange}
                />
              </div>
              {this.state.showErrorMessage && (
                <div className={styles.errorMessage}>
                  Please enter all fields
                </div>
              )}
              <div className={styles.submit} onClick={this.handleSubmit}>
                SUBMIT
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default AgeGate

import React from "react"
import styles from "../styles/components/text-block.module.scss"
import classNames from "classnames/bind"

import plus from "../images/plus.png"
import minus from "../images/minus.png"

let cx = classNames.bind(styles)

const TextBlock = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = { revealText: this.props.isExpandable ? false : null }
    this.toggleRevealText = this.toggleRevealText.bind(this)
  }

  componentDidUpdate(prevState) {
    if (this.props.isExpandable === true && prevState.revealText === true) {
      this.setState({ revealText: true })
    }
  }

  toggleRevealText() {
    if (this.state.revealText !== null) {
      this.setState({ revealText: !this.state.revealText })
    }
  }
  render() {
    let headerClass = cx("header", { white: this.props.whiteText })
    let textClass = cx("bodyText", { white: this.props.whiteText })
    //console.log("revealText " + this.state.revealText)
    return (
      <a
        href={this.props.link ? this.props.link : null}
        target="__blank"
        style={{ cursor: "pointer" }}
        className={styles.pressLink}
      >
        <div className={styles.container} onClick={this.toggleRevealText}>
          <div className={styles.flexContainer}>
            {!this.state.revealText && this.props.isExpandable && (
              <div className={styles.expandingIcon}>
                <img src={plus} alt="" />
              </div>
            )}
            {this.state.revealText && this.props.isExpandable && (
              <div className={styles.expandingIcon}>
                <img className={styles.minusSign} src={minus} alt="" />
              </div>
            )}
            <div className={headerClass}>{this.props.header}</div>
          </div>
          {(this.state.revealText || !this.props.isExpandable) && (
            <div className={textClass}>
              {this.props.bodyText}
              {this.props.socialLink && (
                <span>
                  <a
                    style={{ textDecoration: "none", color: "#4f0385" }}
                    href={this.props.socialLink}
                    target="__blank"
                  >
                    {this.props.socialText}
                  </a>
                </span>
              )}
              {this.props.remainingText && (
                <span>{this.props.remainingText}</span>
              )}
              {this.props.email && (
                <span>
                  {!this.props.under13 && (
                    <a
                    style={{ textDecoration: "none", color: "#4f0385" }}
                    href={this.props.emailLink}
                  >
                    {this.props.email}
                  </a>
                  )}
                  {this.props.under13 && this.props.email}
                </span>
              )}
            </div>
          )}
        </div>
      </a>
    )
  }
}

export default TextBlock

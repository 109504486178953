import React from "react"
import styles from "../styles/components/faq.module.scss"
import Reveal from "react-reveal/Reveal"
import TextBlock from "./textBlock"

import buttonSlime from "../images/button-slime.png"

import backgroundImage from "../images/faq-background.jpg"

const Faq = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = { showAll: false, defaultListLength: 7 }
    this.toggleShowAll = this.toggleShowAll.bind(this)
  }

  toggleShowAll() {
    this.setState({ showAll: !this.state.showAll })
  }

  render() {
    const textBlocks = [
      {
        header: "What is the age recommendation for Nickelodeon Slime City?",
        bodyText:
          "Nickelodeon Slime is for EVERYONE! But…it’s most appreciated by kids over 3. And for Slimings, kids must be at least 2 years old.",
      },
      {
        header: "What should I bring?",
        bodyText: "Your LOVE of Slime. Oh, and your ticket.",
      },
      {
        header: "What items are not allowed?",
        bodyText:
          "The following items will not be permitted inside Nickelodeon Slime City: large oversized (8” x 17” x 18”) bags/luggage; weapons of any kind; illegal substances or alcoholic beverages; tripods/monopods; animals (other than documented service animals); skateboards, roller-skates, “wheelie” shoes (even though they’re super cool); outside food and beverages, or laser pointers.",
      },
      {
        header: "Is Nickelodeon Slime City wheelchair/ADA accessible?",
        bodyText: "Totally!",
      },
      {
        header: "Is there an audio tour available?",
        bodyText: "Unfortunately, no.",
      },
      {
        header: "What is the best way to dress for this experience?",
        bodyText: `Come dressed for a day of play, but don’t worry, we’ve thought of everything!  Everyone will receive a poncho to
wear during their sliming so you can get right back into your car afterwards!`,
      },
      {
        header:
          "Is there food available? Can I bring in my own food and drink?",
        bodyText:
          "Water and confections will be available for purchase. No outside food and drink can be brought inside.",
      },
      {
        header: "Are foreign language materials available?",
        bodyText: "No",
      },
      {
        header: "What are the hours of operation?",
        bodyText: `Saturday - 9:00am-8pm

Sunday - 10am-7pm

Wednesday, Thursday and Friday – 10:30am-6:30pm.

Monday and Tuesday – Closed`,
      },
      {
        header: "Is stroller/coat check available?",
        bodyText:
          "We do not have dedicated stroller space or coat check, so please leave these items in your car.",
      },
      {
        header: "Where is the experience located?",
        bodyText:
          "Nickelodeon Slime City is located in Buckhead at the Piedmont-Peachtree Crossing Shopping Center at 3330 Piedmont Road NE, Atlanta, GA.",
      },
      {
        header: "Do you have parking?",
        bodyText: "Yes.",
      },

      {
        header: "Can I rent space for a private event?",
        bodyText: "To learn more about a private event please contact us at ",
        email: "slimecity@nick.com",
        emailLink: "mailto:slimecity@nick.com",
      },
      {
        header: "Can I bring my pet?",
        bodyText: "No, only service animals will be permitted.",
      },
      {
        header: "Can I take photos/videos inside Nickelodeon Slime City?",
        bodyText:
          "You’d better! In fact, we want you to document this day to remember for the rest of your life! And show it off to your family and friends!",
      },
      {
        header:
          "Who can I speak to about press coverage of Nickelodeon Slime City?",
        bodyText: "Please send any Press Inquiries to ",
        email: "slimecity@nick.com",
        emailLink: "mailto:slimecity@nick.com",
      },
      {
        header: "Do you have restrooms?",
        bodyText: "Of course we do!",
      },
      {
        header: "What is your refund policy?",
        bodyText: "All ticket sales are non-refundable.",
      },
      {
        header: "Are there Nickelodeon items to purchase?",
        bodyText:
          "Yes, there is Slime-themed merchandise available for purchase.",
      },
      {
        header: "What forms of payment do you accept at the store?",
        bodyText:
          "Credit card and debit card only.  Cash will not be accepted.",
      },
      {
        header: "How much are tickets?",
        bodyText:
          "General Admission is $30 $25 (Hurry! Early-Bird Pricing ends June 14th!). Children under 2 are free!",
      },
      {
        header: "What is the age for children where they would be free?",
        bodyText: "Under 2.",
      },
      {
        header:
          "Where can I get more information regarding future ticket releases?",
        bodyText:
          "Please sign up for our newsletter for updates about future Nickelodeon Slime City news.",
          hideInU13: "true",
      },
      {
        header:
          "How long does it take to go through/How long should I plan to be there?",
        bodyText:
          "The journey through Nickelodeon Slime City lasts up to about one hour.",
      },
      {
        header: "Why do I have to choose a time to enter the experience?",
        bodyText:
          "We’ve got to share the love of Slime! Admission is a timed-entry where your ticket is for a specific time slot so that we can ensure everyone gets a chance to explore Slime City safely.",
      },
      {
        header: "How early should I arrive?",
        bodyText:
          "Guests are encouraged to arrive at their designated time slot.",
      },
      {
        header: "What if I arrive after my time slot?",
        bodyText:
          "Don’t be late, Slime awaits! Your ticket is only valid for the date and time purchased. If you miss your timeslot, please see a Slime City representative when you arrive, or call Showclix customer service at 1-888-718-4253. The box office will do their best to accommodate you into the next available time slot. However please note, there is no guarantee a subsequent time slot will become available.",
      },
      {
        header: "Where can I get more information?",
        bodyText: "Follow us on Instagram ",
        socialLink: "https://www.instagram.com/nickslimecity/",
        socialText: "@NickSlimeCity ",
        remainingText:
          " for more details. If you have additional questions please email us at: ",
        email: "slimecity@nick.com",
        emailLink: "mailto:slimecity@nick.com",
        hideInU13: "true",
      },
    ]

    return (
      /* add paddingTop: 150px to the outer div if slime border added */
      <div
        style={{
          backgroundImage: "url(" + backgroundImage + ")",
          backgroundSize: "cover",
        }}
      >
        <div className={styles.container}>
            <div className={styles.header}>FAQ</div>
          <div className={styles.flexContainer}>
            {textBlocks.map((item, i) => {
              if (!this.state.showAll && i >= this.state.defaultListLength) {
                return
              } else {
                  if (this.props.under13 && item.hideInU13) {
                    return
                  }
                return (
                    <TextBlock
                      key={i}
                      header={item.header}
                      bodyText={item.bodyText}
                      isExpandable={true}
                      socialLink={item.socialLink ? item.socialLink : null}
                      socialText={item.socialText ? item.socialText : null}
                      remainingText={
                        item.remainingText ? item.remainingText : null
                      }
                      email={item.email ? item.email : null}
                      emailLink={item.emailLink ? item.emailLink : null}
                      under13={this.props.under13}
                    />
                )
              }
            })}
          </div>
          <div className={styles.showMoreButton} onClick={this.toggleShowAll}>
            {/*<div className={styles.buttonSlime}>
              <img src={buttonSlime} alt="slime" />
            </div>*/}
            <span>{this.state.showAll ? "Show Less" : "Show More"}</span>
          </div>
        </div>
      </div>
    )
  }
}

export default Faq

import React from 'react'

const Footer = (props) => {
	return (
		<footer
          style={{
            fontSize: "12px",
            lineHeight: "14px",
            color: "#3cb64b",
            width: "100%",
            textAlign: "center",
            paddingTop: "14px",
            paddingBottom: "21px",
            paddingLeft: "25px",
            paddingRight: "25px",
          }}
        >
          © {new Date().getFullYear()} VIACOM INTERNATIONAL INC. ALL RIGHTS
          RESERVED. NICKELODEON, NICK, SLIME, SLIMECITY AND ALL RELATED TITLES,
          LOGOS AND CHARACTERS ARE TRADEMARKS OF VIACOM INTERNATIONAL.
          <br />
          <br />
          {!props.under13 && (
          <a
            href="http://srp.viacom.com/sitefaq.html"
            target="__blank"
            style={{ textDecoration: "none", color: "inherit", fontSize: '14px', fontWeight: 'bold', letterSpacing: '2px',}}
          >
            ADCHOICES
          </a>
          )}
        </footer>
	)
}

export default Footer
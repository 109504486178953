import React from "react"
import Helmet from "react-helmet"
import { withPrefix } from "gatsby"
import Reveal from "react-reveal/Reveal"
import styles from "../styles/components/email-collect.module.scss"
import classNames from "classnames/bind"
import cornerSlime from "../images/slime-top-left-corner.png"

let cx = classNames.bind(styles)

//const emailScript = withPrefix("/js/emailScript.js")

const EmailCollect = class extends React.Component {
  constructor(props) {
    super(props)

    this.state = { checked: false, showCheckboxError: false, submitted: false }

    //this.loadScript = this.loadScript.bind(this)
    this.checkSubmissionStatus = this.checkSubmissionStatus.bind(this)
  }

  componentDidMount() {
    if (this.props.under13 === false) {
      const s = document.createElement("script")
      s.src = withPrefix("/js/emailScript.js")
      s.type = "text/javascript"
      s.defer = true

      // setting intentional script loading delay to ensure correct load order
      setTimeout(() => document.body.appendChild(s), 750)
    }
  }

  componentDidUpdate() {}

  onClickCheckbox() {
    this.setState({ checked: !this.state.checked })
    if (this.state.showCheckboxError) {
      this.setState({ showCheckboxError: false })
    }
  }

  handleCheckboxError() {
    this.setState({ showCheckboxError: this.state.checked ? false : true })
  }

  checkSubmissionStatus() {}

  render() {
    this.checkSubmissionStatus()
    let checkboxClass = cx("checkbox", { checked: this.state.checked })
    return (
      <div className={styles.container}>
          <div className={styles.modalContainer}>
            {/*          <div className={styles.cornerSlime}>
            <img src={cornerSlime} alt="" />
          </div>*/}
            <div className={styles.dropShadow} />
            <div className={styles.modalFlexContainer}>
              <div
                className={styles.blockSubmit}
                style={{ display: this.state.checked ? "none" : "" }}
                onClick={this.handleCheckboxError.bind(this)}
              />
                <div className={styles.header}>Join Our Slime List</div>
              <div className={styles.subheader}>
                Enter your email to receive updates from Nickelodeon Slime City.
              </div>
                <form id="slimecity_mailing_list-container" />
              {!this.state.submitted && (
                <div className={styles.agreement}>
                  <div
                    className={styles.checkboxError}
                    style={{
                      display: this.state.showCheckboxError ? "" : "none",
                    }}
                  >
                    You must check this box to continue
                  </div>
                  <div className={styles.flexContainer}>
                    <div
                      className={checkboxClass}
                      onClick={this.onClickCheckbox.bind(this)}
                    />
                    <div className={styles.statement}>
                      I agree to receive marketing communications from
                      Nickelodeon, and in doing so, I agree to the{" "}
                      <a
                        href="https://www.nick.com/legal/ussoge/terms-of-use"
                        target="_blank"
                      >
                        Terms of Use
                      </a>{" "}
                      and{" "}
                      <a
                        href="https://www.nick.com/legal/wwd7m0/privacy-policy"
                        target="_blank"
                      >
                        Privacy Policy
                      </a>
                      . Nickelodeon may share your information with social media
                      platforms to better reach you.
                    </div>
                  </div>
                </div>
              )}{" "}
            </div>
          </div>
      </div>
    )
  }
}

export default EmailCollect

import React from "react"
import styles from "../styles/components/hero.module.scss"

import Reveal from "react-reveal/Reveal"

import Bumper from "../components/bumper"

//import backgroundImage from "../images/background-with-slime-optimized.jpg"
import backgroundImage from "../images/purple-background.jpg"
import slimeGirl from "../images/hero-slime-girl-2.png"
//import slimeCityLogo from "../images/slimecity-logo.png"
import slimeCityLogo from "../images/logo-with-atlanta.png"
import buttonSlime from "../images/button-slime.png"

const Hero = class extends React.Component {
  constructor(props) {
    super(props)

    this.state = { bumperOpen: false }
  }

  handleClick() {
    if (this.props.under13) {
      this.setState({ bumperOpen: true })
    } else {
      window.open(
        "https://www.showclix.com/event/nickelodeon-slime-city",
        "__blank"
      )
    }
  }

  render() {
    return (
      <>
        {this.state.bumperOpen && (
          <Bumper link="https://www.showclix.com/event/nickelodeon-slime-city" />
        )}
        <div className={styles.container}>
          <Reveal effect="fadeIn">
            <div className={styles.slimeGirl}>
              <img src={slimeGirl} />
              <div className={styles.overlay} />
            </div>
          </Reveal>
          <Reveal effect="fadeIn">
            <div className={styles.logo}>
              <img src={slimeCityLogo} alt="slime city logo" />
            </div>
          </Reveal>
          <div className={styles.flexContainer}>
            <Reveal effect="fadeIn" fraction={0.5}>
              <div className={styles.text}>
                Get ready for the SLIME of your life as you discover a world of
                SLIME REIMAGINED at Nickelodeon’s Slime City!
              </div>
            </Reveal>
            <Reveal effect="fadeInUpShorter" fraction={0.5}>
              <div
                className={styles.ticketsLink}
                onClick={this.handleClick.bind(this)}
              >
                {/*<div className={styles.buttonSlime}>
                <img src={buttonSlime} alt="slime" />
              </div>*/}
                <span>Get Tickets</span>
              </div>
            </Reveal>
          </div>
        </div>
      </>
    )
  }
}

export default Hero

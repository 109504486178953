import React from "react"
import styles from "../styles/components/contact-form.module.scss"
import axios from "axios"
import Reveal from "react-reveal/Reveal"

//import backgroundImage from "../images/pattern-background.png"
import backgroundImage from "../images/contact-background.jpg"
import buttonSlime from "../images/button-slime.png"

const ContactForm = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: "",
      email: "",
      message: "",
      showForm: true,
      savedName: "",
      sendingMessage: false,
      messageSent: false,
      messageError: false,
    }
    this.handleFormToggle = this.handleFormToggle.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleFormToggle = () => {
    this.setState(prevState => {
      const { showForm } = prevState
      return {
        showForm: !showForm,
      }
    })
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  handleSubmit = event => {
    event.preventDefault()
    this.setState({
      sendingMessage: true,
    })
    console.log(this.state.name + this.state.email + this.state.message)
    this.sendMessage()
    this.handleFormToggle()
  }

  sendMessage = () => {
    /*
    const formData = new FormData()
    formData.append(GOOGLE_FORM_NAME_ID, this.state.name)
    formData.append(GOOGLE_FORM_EMAIL_ID, this.state.email)
    formData.append(GOOGLE_FORM_COMPANY_ID, this.state.company)
    formData.append(GOOGLE_FORM_MESSAGE_ID, this.state.message)
*/
    let formData = {
      name: this.state.name,
      email: this.state.email,
      message: this.state.message,
    }
    axios
      .post(
        "https://4dsf05lhqg.execute-api.us-east-1.amazonaws.com/staging/contact-us",
        formData
      )
      .then(() => {
        this.setState({
          savedName: this.state.name,
          name: "",
          email: "",
          message: "",
          messageSent: true,
          sendingMessage: false,
        })
      })
      .catch(() => {
        this.setState({ messageError: true, sendingMessage: false })
      })
  }

  render() {
    return (
      /* add in paddingTop: 150px if image divider with slime cutout added back */
      <div
        style={{
          backgroundImage: "url(" + backgroundImage + ")",
          backgroundSize: "cover",
        }}
      >
        <div className={styles.container}>
          <div className={styles.flexContainer}>
              <div className={styles.header}>
                <p>Contact Us for Any Slime City Inquiries</p>
              </div>
            <form
              onSubmit={this.handleSubmit}
              className={styles.contactForm}
              autoComplete="off"
            >
                <div className={styles.group}>
                  <input
                    type="text"
                    name="name"
                    value={this.state.name}
                    required
                    placeholder="Your name"
                    onChange={this.handleChange}
                  />
                </div>
                <div className={styles.group}>
                  <input
                    type="text"
                    name="email"
                    value={this.state.email}
                    required
                    placeholder="Your email"
                    onChange={this.handleChange}
                  />
                </div>
                <div className={styles.group}>
                  <textarea
                    name="message"
                    value={this.state.message}
                    rows="6"
                    maxLength="500"
                    required
                    placeholder="Message"
                    onChange={this.handleChange}
                    className={styles.message}
                  />
                </div>
                <div className={styles.submitButton}>
                  <div className={styles.buttonSlime}>
                    <img src={buttonSlime} alt="slime" />
                  </div>
                  <input type="submit" value="Send" />
                </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

export default ContactForm

import React from "react"
import styles from "../styles/components/dropdown.module.scss"
import classNames from "classnames/bind"

import downArrow from "../images/down-arrow.png"

let cx = classNames.bind(styles)

const Dropdown = class extends React.Component {
  constructor(props) {
    super(props)

    this.state = { open: false, activeItem: null }
    this.toggleDropdown = this.toggleDropdown.bind(this)
  }

  toggleDropdown() {
    this.setState({ open: !this.state.open })
  }

  onClick(item) {
    const label = this.props.label
    const onChange = this.props.onChange
    const selection = item.target.attributes
    this.setState({ activeItem: selection })
    onChange(label, selection.value.value)
  }

  render() {
    const { options } = this.props
    const listItems = options.map((option, i) => {
      let listItemClass = cx("listItem", {
        active: this.state.activeItem === option.label,
      })
      return (
        <li
          className={listItemClass}
          label={option.label}
          value={option.value}
          key={i}
          onClick={this.onClick.bind(this)}
        >
          {option.label}
        </li>
      )
    })

    let arrowClass = cx("downArrow", { rotate180: this.state.open })

    let dropdownLabel
    if (this.state.activeItem !== null) {
      // this.state.activeItem stores item.target.attributes of clicked element
      if (this.props.label === "Month") {
        dropdownLabel = this.state.activeItem.label.value
      } else {
        dropdownLabel = this.state.activeItem.value.value
      }
    }

    return (
      <div className={styles.dropdownContainer} onClick={this.toggleDropdown}>
        <span>
          {this.state.activeItem === null ? this.props.label : dropdownLabel}
        </span>
        <div className={arrowClass}>
          <img src={downArrow} alt="" />
        </div>
        {this.state.open && <ul className={styles.listClass}>{listItems}</ul>}
      </div>
    )
  }
}

export default Dropdown

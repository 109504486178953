import React from "react"
import styles from "../styles/components/image-section.module.scss"

const ImageSection = props => {
  return (
    <div className={styles.container}>
      <img src={props.imageURL} alt="" />
    </div>
  )
}

export default ImageSection

import React from "react"
import styles from "../styles/components/image-feed.module.scss"

import image1 from "../images/social-images/image1.png"
import image2 from "../images/social-images/image2.png"
import image3 from "../images/social-images/image3.png"
import image4 from "../images/social-images/image4.png"
import image5 from "../images/social-images/image5.png"
import image6 from "../images/social-images/image6.png"
import image7 from "../images/social-images/image7.png"
import image8 from "../images/social-images/image8.png"
import image9 from "../images/social-images/image9.png"
import image10 from "../images/social-images/image10.png"

import arrowButton from "../images/arrow-button2.png"

/* global vars to determine container size if mobile */
let screenWidth
let isMobile = false
let containerWidth
let sliderIncrement

const images = [
  { url: image1, link: "https://instagram.com" },
  { url: image2, link: "https://instagram.com" },
  { url: image3, link: "https://instagram.com" },
  { url: image4, link: "https://instagram.com" },
  { url: image5, link: "https://instagram.com" },
  { url: image6, link: "https://instagram.com" },
  { url: image7, link: "https://instagram.com" },
  { url: image8, link: "https://instagram.com" },
  { url: image9, link: "https://instagram.com" },
  { url: image10, link: "https://instagram.com" },
  { url: image1, link: "https://instagram.com" },
  { url: image2, link: "https://instagram.com" },
  { url: image3, link: "https://instagram.com" },
  { url: image4, link: "https://instagram.com" },
  { url: image5, link: "https://instagram.com" },
  { url: image6, link: "https://instagram.com" },
  { url: image7, link: "https://instagram.com" },
  { url: image8, link: "https://instagram.com" },
  { url: image9, link: "https://instagram.com" },
  { url: image10, link: "https://instagram.com" },
]

const ImageFeed = class extends React.Component {
  constructor(props) {
    super(props)

    this.state = { transformX: 0, containerWidth: null }

    this.slideImages = this.slideImages.bind(this)
  }

  componentDidMount() {
    // calculate screen size to determine container size
    screenWidth = window.innerWidth
    if (screenWidth <= 768) {
      isMobile = true
    }

    this.calculateContainerWidth(images.length)
  }

  setContainerWidth(width) {
    this.setState({ containerWidth: width })
  }

  calculateContainerWidth(numImages) {
    let imageWidth
    if (isMobile) {
      imageWidth = 192
      sliderIncrement = 192
    } else {
      imageWidth = 264
      sliderIncrement = 264
    }
    let containerWidth = (numImages / 2) * imageWidth
    this.setState({ containerWidth: containerWidth })
    //this.setContainerWidth(containerWidth)
    //return containerWidth
  }

  slideImages(direction) {
    console.log("transformX: " + this.state.transformX)
    switch (direction) {
      case "left":
        if (this.state.transformX < 0) {
          // edge case
          if (this.state.transformX + sliderIncrement > 0) {
            this.setState({ transformX: 0 })
          } else {
            this.setState({
              transformX: this.state.transformX + sliderIncrement,
            })
          }
        }
        break
      case "right":
        if (
          Math.abs(this.state.transformX) <=
          this.state.containerWidth - window.innerWidth
        ) {
          // if a full transform increment of 264 is greater than the difference of
          // container and screen width plus current transformX, calculate the
          // amount left to shift
          if (
            window.innerWidth +
              Math.abs(this.state.transformX - sliderIncrement) >
            this.state.containerWidth
          ) {
            let offset =
              this.state.containerWidth -
              (window.innerWidth + Math.abs(this.state.transformX))

            this.setState({ transformX: this.state.transformX - offset })
          } else {
            this.setState({
              transformX: this.state.transformX - sliderIncrement,
            })
          }
        }
        break
      default:
        break
    }
  }
  render() {
    const imageArray = images.map((image, i) => {
      return (
        <a href={image.link} target="_blank" rel="noopener noreferrer" key={i}>
          <div className={styles.imageTile}>
            <img src={image.url} alt="" />
          </div>
        </a>
      )
    })

    return (
      <div className={styles.viewPort}>
        <div
          className={styles.container}
          style={{ width: this.state.containerWidth + "px" }}
        >
          <div
            className={styles.slider}
            style={{ transform: "translateX(" + this.state.transformX + "px)" }}
          >
            <div className={styles.flexContainer}>{imageArray}</div>
          </div>
        </div>
        <div>
          <div
            className={styles.leftArrow}
            onClick={() => this.slideImages("left")}
          >
            <img src={arrowButton} alt="" />
          </div>
          <div
            className={styles.rightArrow}
            onClick={() => this.slideImages("right")}
          >
            <img src={arrowButton} alt="" />
          </div>
        </div>
      </div>
    )
  }
}

export default ImageFeed
